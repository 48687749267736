import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ENG from './ENG.json';
import CHI from './CHI.json';
import FRA from './FRA.json';
import SPA from './SPA.json';
import HIN from './HIN.json';
import POR from './POR.json';
import ITA from './ITA.json';
import RUS from './RUS.json';
import ARA from './ARA.json';
import BEN from './BEN.json';
import DEU from './DEU.json';
import JPN from './JPN.json';
import IND from './IND.json';
import TUR from './TUR.json';
import URD from './URD.json';
import PAN from './PAN.json';
import KOR from './KOR.json';
import JAV from './JAV.json';
import TEL from './TEL.json';
import TAM from './TAM.json';
import MAR from './MAR.json';
import VIE from './VIE.json';
import THA from './THA.json';
import POL from './POL.json';
import GUJ from './GUJ.json';
import KAN from './KAN.json';
import HAU from './HAU.json';
import UKR from './UKR.json';
import MAL from './MAL.json';
import ORI from './ORI.json';
import SUN from './SUN.json';
import BHO from './BHO.json';
import ZUL from './ZUL.json';
import MAI from './MAI.json';
import AMH from './AMH.json';
import SND from './SND.json';
import YOR from './YOR.json';
import AWA from './AWA.json';
import CEB from './CEB.json';
i18n.use(initReactI18next).init({
  lng: 'ENG',
  fallbackLng: 'ENG',
  resources: {
    ENG: ENG,
    CHI: CHI,
    FRA: FRA,
    SPA: SPA,
    HIN: HIN,
    POR: POR,
    ITA: ITA,
    RUS: RUS,
    ARA: ARA,
    BEN: BEN,
    DEU: DEU,
    JPN: JPN,
    IND: IND,
    TUR: TUR,
    URD: URD,
    PAN: PAN,
    KOR: KOR,
    JAV: JAV,
    TEL: TEL,
    TAM: TAM,
    MAR: MAR,
    VIE: VIE,
    THA: THA,
    POL: POL,
    GUJ: GUJ,
    KAN: KAN,
    HAU: HAU,
    UKR: UKR,
    MAL: MAL,
    ORI: ORI,
    SUN: SUN,
    BHO: BHO,
    ZUL: ZUL,
    MAI: MAI,
    AMH: AMH,
    SND: SND,
    YOR: YOR,
    AWA: AWA,
    CEB: CEB,
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
