import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import TopHeader from '../components/TopHeader';
import Loader from '../components/Loader';
import MessageBox from '../components/MessageBox';
import ButtonClick from '../components/ButtonClick';
import logo from '../images/logo-black.png';

import mail from '../images/login-mail-ico.svg';
import pass from '../images/login-lock-ico.svg';
import CryptoES from 'crypto-es';
import API from '../services/ApiLists';
import { ApiCall } from '../services/ApiCall';
import { useTranslation } from 'react-i18next';
import '../languages/i18n';
import { storeUserData, removeUserData, getUserData } from '../Functions/Functions';
import BusinessFooter from '../components/BusinessFooter';

function ChangeEmailPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user, setUser] = useState();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = React.useState(false);
  const [error_title, seterror_title] = useState('');

  useEffect(() => {
    window.addEventListener('message', (message) => {
      if (window.isNative) {
        window.ReactNativeWebView.postMessage(JSON.stringify('dddjdkdkjk'));
      }
    });

    return () => {
      window.removeEventListener('message', (message) => {
        // alert(JSON.stringify(message.data));
        if (window.isNative) {
          window.ReactNativeWebView.postMessage(JSON.stringify('sklklsnk'));
        }
      });
    };
  }, []);

  useEffect(async() => {
    const userData = await getUserData();
    console.log('userDatauserDatauserData', userData);
    if (userData) {
      setUser(userData);
    }
  },[])
  function changePassword() {
    if (email == '' && password == '') {
      setError(true);
      setLoader(false);
      seterror_title('Required Fields are empty!');
    } else {
      var formData = new FormData();
      setLoader(true);
      formData.append('user_id', user?.id);
      if (email) {
        formData.append('email', email);
      }
      if (password) {
        formData.append('password', password);
      }
      var header={
        Authorization: 'Bearer ' + user?.access_token,
      }

      ApiCall('Post', API.changeEmailPassword, formData,header)
        .catch((error) => {
          setLoader(false);
          console.log('erorr reponse', error);
          alert('Error occured!');
        })
        .then(async (resp) => {
          setLoader(false);
          console.log(' changeEmailPassword', resp);
          if (resp.data.code == 'update_user') {
            alert(resp.data.message)
            removeUserData()
            navigate('/home')
          } else {
            alert(resp?.data?.message);
          }
        });
    }
  }

  return (
    <div class="container-fluid">
      <TopHeader title={t('changePassword.change_password')} />
      {loader && <Loader />}
      <section class="content-sec row yellow-bg">
        <div class="login-wrap">
        <img class="img-fluid sign-logo" src={logo} alt="Vote and Fun" />

          <h5 class="mb-4">{t('changePassword.change_password')}</h5>

          <div class="login-form">
            <div class="form-group mb-3">
              <img class="ico" src={mail} alt="ico" />
              <input
                type="text"
                class="form-control"
                placeholder={t('placeHolders.email')}
                onChange={(text) => setEmail(text.target.value)}
                value={email}
                required
              />
            </div>

            <div class="form-group mb-2">
              <img class="ico" src={pass} alt="ico" />
              <input
                type="password"
                class="form-control"
                placeholder={t('placeHolders.password')}
                onChange={(text) => setPassword(text.target.value)}
                value={password}
                required
              />
            </div>

            <div class="form-group mb-3">
              <ButtonClick title={t('changePassword.submit')} onClickftn={() => changePassword()} />
            </div>
          </div>
        </div>
      </section>
      <BusinessFooter />
      {error && <MessageBox error={error} setError={setError} title={error_title} color={'black'} />}
    </div>
  );
}

export default ChangeEmailPassword;
