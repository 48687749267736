import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import TopHeader from "../../components/BusinessHeader";
import BusinessFooter from "../../components/BusinessFooter";

import { useTranslation } from "react-i18next";
import "../../languages/i18n";
import API from "../../services/ApiLists";
import { ApiCall } from "../../services/ApiCall";
import { getUserData } from "../../Functions/Functions";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CustomStripeForm from './CheckoutForm';

export default function Election() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [cardElection, setCardElection] = useState();
  const [user, setUser] = useState();

  const stripePromise = loadStripe('pk_test_51KgNrvLcjxcfgabxFdO76EPbUagpPVnTh2uch1ZXXXZbTQtpqIyfEtRtIlzm1FkwsAOPUtyw4zI4K7hoGbQ60pAo00b5VfGdV8');

  useEffect(async () => {
    const userData = await getUserData();
    if (userData) {
      setUser(userData);
    }
    const electionId = [];
    if (location?.state?.acountlist?.ended_elections != undefined) {
      location?.state?.acountlist?.ended_elections.map((item) => {
        electionId.push(item.election_id);
      });
      setCardElection(electionId.join());
      console.log("electionId", electionId.join());
    }
  }, []);
  console.log("loc", location?.state);
 
  return (
    <div class="container-fluid">
      <TopHeader title={t("Header.PAYMENT")} />
      {/* <!-- Content Section Starts here --> */}
      <section class="content-sec row yellow-bg with-gray pb-5">
        <div class="login-wrap election-form">
          <h5 class="text-center my-5">{t("Header.PAYMENT")}</h5>
          <div class="col-12">
            {/* <h6>SELECT PAYMENT METHODS</h6> */}
            <div class="payment-type-btn">
              <img class="img-fluid" src="images/card-ico.svg" alt="ico" />
              {t("Payment.Stripe")}
            </div>
            
            <hr class="mx-5 my-4" />
            
            <Elements stripe={stripePromise}>
              <CustomStripeForm />
            </Elements>

          </div> 
        </div>
      </section>
    </div>
  );
}
