import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../languages/i18n";
import API from "../../services/ApiLists";
import { ApiCall } from "../../services/ApiCall";
import { getUserData } from "../../Functions/Functions";


const CustomStripeForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardNumber, setCardNumber] = useState();
  const [cardMonth, setCardmonth] = useState();
  const [cardyear, setCardyear] = useState();
  const [cardcvv, setCardcvv] = useState();
  const [cardholderName, setCardholderName] = useState('');
  const [cardSave, setCardSave] = useState(false);
  const [cardElection, setCardElection] = useState();
  const [user, setUser] = useState();

  useEffect(async () => {
    const userData = await getUserData();
    if (userData) {
      setUser(userData);
    }

    const electionId = [];
    if (location?.state?.acountlist?.ended_elections != undefined) {
      location?.state?.acountlist?.ended_elections.map((item) => {
        electionId.push(item.election_id);
      });
      setCardElection(electionId.join());
      console.log("electionId", electionId.join());
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardholderName, // Pass the cardholder name to the billing details
      },
    });

    if (error) {
      console.error(error);
      setError(error.message);
    } else {
      console.log('PaymentMethod', paymentMethod);
      // Send the paymentMethod.id to your server to complete the payment
      var formData = new FormData();
      formData.append("business_id", location?.state?.preview?.business_details?.business_id);
      formData.append("payment_method_id", paymentMethod.id);
      formData.append(
        "amount",
        location?.state?.status == "account"
          ? location?.state?.acountlist?.total_due_amount
          : location?.state?.acountlist.amount_to_be_paid
      );
      formData.append(
        "payment_for",
        location?.state?.status == "account" ? "Outstanding" : "VFPurchase"
      );
      if (location?.state?.status == "account") {
        formData.append("election_ids", cardElection);
      } else {
        formData.append("purchased_vf_value", location?.state?.acountlist?.additional_vf_value);
      }
      formData.append("save_status", cardSave);

      ApiCall("Post", API.BusinessPurchasePayment, formData, {
        Authorization: "Bearer " + user?.access_token,
        Accept: "application/json",
      })
        .catch((error) => {
          console.log("erorr reponse", error);
          //   reject(error.response);
        })
        .then((resp) => {
          console.log("Election list", resp.data);
          let rst = localStorage.getItem('cryptedData');
          navigate('/BusinessHome/'+rst);
        });

    }
  };

  const handleCardholderNameChange = (event) => {
    setCardholderName(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '1rem', color: '#333', display: 'none' }}>Secure Payment</h2>
      <div style={{ marginBottom: '1rem', padding: '10px' }}>
        <div class="row">
          <div style={{width: '100%'}}>
            <label htmlFor="card-number" style={{ font: "16px 'Roboto', sans-serif", display: 'block', marginBottom: '0.5rem', color: '#333' }}>
              Card Number
            </label>
            <div style={{ padding: '8px', borderRadius: '0.25rem', border: '1px solid #ccc', backgroundColor: '#fff' }}>
              <CardNumberElement
                id="card-number"
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      padding: '30px',
                      color: '#333',
                      '::placeholder': {
                        color: '#757575',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                  placeholder: '**** **** **** 7911',
                }}
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div style={{width: '50%'}}>
            <label htmlFor="card-expiry" style={{ font: "16px 'Roboto', sans-serif", display: 'block', marginTop: '1rem', marginBottom: '0.5rem', color: '#333' }}>
              Expiry Date
            </label>
            <div style={{ padding: '8px', borderRadius: '0.25rem', border: '1px solid #ccc', backgroundColor: '#fff' }}>
              <CardExpiryElement
                id="card-expiry"
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      color: '#333',
                      '::placeholder': {
                        color: '#757575',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
            </div>
          </div>
          <div style={{width: '50%'}}>
            <label htmlFor="card-cvc" style={{ font: "16px 'Roboto', sans-serif", display: 'block', marginTop: '1rem', marginBottom: '0.5rem', color: '#333' }}>
              CVV
            </label>
            <div style={{ padding: '8px', borderRadius: '0.25rem', border: '1px solid #ccc', backgroundColor: '#fff' }}>
              <CardCvcElement
                id="card-cvc"
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      color: '#333',
                      '::placeholder': {
                        color: '#757575',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                  placeholder: 'CVV',
                }}
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div style={{width: '100%'}}>
            <label htmlFor="cardholder-name" style={{ font: "16px 'Roboto', sans-serif", display: 'block', marginTop: '1rem', marginBottom: '0.5rem', color: '#333' }}>
              Card Holder Name
            </label>
            <input
              type="text"
              id="cardholder-name"
              value={cardholderName}
              onChange={handleCardholderNameChange}
              placeholder="Card Holder Name"
              style={{ width: '100%', padding: '8px', marginBottom: '1rem', fontSize: '16px', border: '1px solid #ccc', borderRadius: '0.25rem' }}
            />
          </div>
        </div>
		    <div class="row save-card">
          <label for="">{t("Payment.Save card information")}</label>
            <div class="form-check form-switch" style={{paddingLeft: '23.5em', marginTop: '-22px'}}>
              <input
                class="form-check-input"
                type="checkbox"
                onChange={(e) => setCardSave(!cardSave)}
                defaultChecked={cardSave}
              />
        </div>
		  </div>
      </div>
	  
		  <div class="row mt-4">
        <div class="total-amt mt-3">
          <p>
            {t("businessPage.TOTAL")}{" "}
            <strong>
              {location?.state?.status == "account"
                ? location?.state?.acountlist.total_due_amount+' '+location?.state?.acountlist.currency
                : location?.state?.acountlist.amount_to_be_paid+' '+location?.state?.acountlist.currency}
            </strong>
          </p>
        </div>
      </div>
		  
      {error && <div style={{ color: 'red', marginBottom: '1rem' }}>{error.message}</div>}
      <button type="submit" disabled={!stripe} class="btn btn-black w-100 py-2 mb-3">
        {t("Payment.PAY NOW")}
      </button>
    </form>
  );
};

export default CustomStripeForm;
